export const getBlackPage = (pathname: string) => {
  // Topic listing page is no longer white text in the hero
  const insightsPage = pathname === "insights" || pathname === "/insights";

  return (
    (["insights"].some((cat) => pathname.includes(cat)) && insightsPage) ||
    (pathname.includes("experts") && pathname.endsWith("bio")) ||
    ["careers", "approach", "leadership", "search"].some((cat) =>
      pathname.endsWith(cat)
    )
  );
};
